/* eslint-disable jsx-a11y/anchor-is-valid */
import Sebrae from '../assets/imgs/sebrae.png';

const BcxSectionD = () => {
    return (
        <section id="sectionD">
            <div className="container">
                <div className="header">
                    <div className="titulo">
                        <img src={Sebrae} alt="Logo da Sebrae" />
                        <h2>
                            Uma Parceria com <br />
                            <strong>soluções para sua empresa</strong>
                        </h2>
                    </div>
                    <div className="descricao">
                        <p>
                            O Sebrae oferecerá 2 capacitações online <span style={{textTransform: 'uppercase'}}>gratuitas</span> aos empreendedores interessados. Inscrições (formulário on-line), itens obrigatórios, orientações junto a Sala do Empreendedor pelo telefone e WhatsApp (51) 3692-2150.
                        </p>
                        <p>
                            Mesmo sem efetivação do crédito, você pode ter acesso a ferramentas que te ajudam a gerenciar melhor seus negócios.
                        </p>
                        <p>
                            <strong>Aproveite essa oportunidade!</strong>
                        </p>
                    </div>
                </div>

                <div className="content">
                    <div className="col">
                        <h3>
                            Finanças empresariais: Controlar para expandir
                        </h3>
                        <a
                            className="btn"
                            href="https://digital.sebraers.com.br/produtos/financas/curso/financas-empresariais-controlar-para-expandir/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acesse aqui
                        </a>

                        <h3>
                            Mapeando o fluxo: Como identificar e reduzir perdas em seu processo
                        </h3>
                        <a
                            className="btn"
                            href="
                            https://digital.sebraers.com.br/produtos/processos/curso/mapeando-o-fluxo-de-valor/
                            "
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Acesse aqui
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BcxSectionD