
import axios from "axios";
import { agencias } from "../shared/formData";

export function sendEmail(data, socios) {
    return new Promise((resolve) => {
        const apiKey = process.env.REACT_APP_SENDINGBLUE_API_KEY;

        const headers = {
            accept: "application/json",
            "api-key": apiKey,
            "content-type": "application/json"
        };

        const { Base64DoArquivo, NomeDoArquivo } = data;

        let attachment = [];

        if (Base64DoArquivo && NomeDoArquivo) {
            const base64 = Base64DoArquivo.split(";")[1].replace("base64,", "");
            attachment.push({ content: base64, name: NomeDoArquivo });
        }

        const valores = {};
        const { PorteDaEmpresa } = data;

        const setAmortizacao = data.NumeroDeParcelas !== '33x' ? '36 meses' : '33 meses';
        const setCarencia = setAmortizacao !== '33 meses' ? '0 zero' : '3 meses';

        let descricaoPorteEmpresa = "";
        switch (PorteDaEmpresa.toLowerCase()) {
            case "mei":
                descricaoPorteEmpresa = "MEI";
                valores.Valor = "R$ 10.000,00";
                valores.Amortizacao = setAmortizacao
                valores.Carencia = setCarencia
                valores.Juro = "1,59%";
                break;
            case "me":
                descricaoPorteEmpresa = "ME";
                valores.Valor = "R$ 50.000,00";
                valores.Amortizacao = setAmortizacao
                valores.Carencia = setCarencia
                valores.Juro = "1,59%";
                break;
            case "epp":
                descricaoPorteEmpresa = "EPP";
                valores.Valor = "R$ 100.000,00";
                valores.Amortizacao = setAmortizacao
                valores.Carencia = setCarencia
                valores.Juro = "1,59%";
                break;
            default:
                break;
        }

        const { AtividadeDaEmpresa } = data;
        let descricaoAtividade = "";

        // eslint-disable-next-line default-case
        switch (AtividadeDaEmpresa) {
            case "1":
                descricaoAtividade = "Indústria";
                break;
            case "2":
                descricaoAtividade = "Comércio";
                break;
            case "3":
                descricaoAtividade = "Serviço";
                break;
            case "7":
                descricaoAtividade = "Agricultor";
                break;
        }

        const getSexo = (sexo) => {
            switch (sexo) {
                case "0":
                    return "Masculino";
                case "1":
                    return "Feminino";
                default:
                    return "Outro";
            }
        }

        const getEstadoCivil = (estadoCivil) => {
            let descricaoEstadoCivil = "";
            switch (estadoCivil) {
                case "1":
                    descricaoEstadoCivil = "Solteiro(a)";
                    break;
                case "0":
                    descricaoEstadoCivil = "Casado(a)";
                    break;
                case "3":
                    descricaoEstadoCivil = "Divorciado(a)";
                    break;
                case "2":
                    descricaoEstadoCivil = "Viúvo(a)";
                    break;
                case "5":
                    descricaoEstadoCivil = "União Estável";
                    break;
                default:
                    break;
            }
            return descricaoEstadoCivil;
        };

        const { FinalidadeDoCredito } = data;

        let descricaoFinalidadeCredito = "";
        switch (FinalidadeDoCredito.toLowerCase()) {
            case "0":
                descricaoFinalidadeCredito =
                    "Capital de Giro (compra de estoque, pagamento de fornecedores, etc.)";
                break;
            case "15":
                descricaoFinalidadeCredito = "Pagamento 13º Salário";
                break;
            case "1":
                descricaoFinalidadeCredito =
                    "Investimento (veículos, máquinas, equipamentos, ampliações, reformas, etc.)";
                break;
            case "16":
                descricaoFinalidadeCredito = "Marketing";
                break;
            case "17":
                descricaoFinalidadeCredito =
                    "Tecnologia (computadores, sistemas, etc.)";
                break;
            default:
                break;
        }

        const {
            RazaoSocial,
            Cnpj,
            InscricaoEstadual,
            Email,
            TelefoneDaEmpresa,
            Agencia,
            EnderecoDaEmpresa,
            BairroDaEmpresa,
            CepDaEmpresa,
            EstadoDaEmpresa,
            CidadeDaEmpresa,
            FaturamentoAnual,
        } = data;

        // eslint-disable-next-line eqeqeq
        const nomeCooperativa = data.Cooperativa != 12 ? "Sicredi" : "Cresol";

        let html = `<html>
                <head></head>
                <body>
                <p>
                <b>RAZÃO SOCIAL : </b> ${RazaoSocial} <br />
                <b>CNPJ : </b> ${Cnpj.replace(/\D/g, '')} <br />
                <b>INSCRIÇÃO ESTADUAL : </b> ${InscricaoEstadual.replace(/\D/g, '')} <br />
                <b>EMAIL EMPRESA : </b> ${Email} <br />
                <b>TELEFONE EMPRESA : </b> ${TelefoneDaEmpresa} <br />
                <b>PORTE DA EMPRESA : </b> ${descricaoPorteEmpresa} <br />
                <i><b>VALOR : </b> ${valores.Valor} <br />
                <b>PRAZO AMORTIZAÇÃO : </b> ${valores.Amortizacao} <br />
                <b>CARÊNCIA : </b> ${valores.Carencia} <br />
                <b>JURO : </b> ${valores.Juro} </i><br />
                <b>ATIVIDADE : </b> ${descricaoAtividade} <br />
                <b>COOPERATIVA : </b> ${nomeCooperativa} <br />
                <b>AGÊNCIA : </b> ${agencias.find(a => a.id === Agencia).name} <br />
                <b>CEP DA EMPRESA : </b> ${CepDaEmpresa} <br />
                <b>ENDEREÇO DA EMPRESA : </b> ${EnderecoDaEmpresa} <br />
                <b>BAIRRO DA EMPRESA : </b> ${BairroDaEmpresa} <br />
                <b>ESTADO DA EMPRESA : </b> ${EstadoDaEmpresa} <br />
                <b>CIDADE DA EMPRESA : </b> ${CidadeDaEmpresa} <br />
                <b>FATURAMENTO ANUAL : </b> ${FaturamentoAnual} <br />`;

        socios.forEach((socio, index) => {
            const {
                Nome,
                Cpf,
                Email,
                EstadoCivil,
                CpfConjuge,
                NomeConjuge,
                EmailConjuge,
                Telefone,
                DataDeNascimento,
                NomeDaMae,
                Rg,
                Sexo,
                Naturalidade,
                Cep,
                Endereco,
                Bairro,
                Estado,
                Cidade,
                TelefoneConjuge,
                DataDeNascimentoConjuge,
                NomeDaMaeConjuge,
                RgConjuge,
                SexoConjuge,
                NaturalidadeConjuge,
                CepConjuge,
                EnderecoConjuge,
                BairroConjuge,
                EstadoConjuge,
                CidadeConjuge,
            } = socio;
            const socioNumber = index + 1;
            const estadoCivilStr = getEstadoCivil(EstadoCivil);

            html += `<b>CPF DO SÓCIO ${socioNumber} : </b> ${Cpf} <br />
      <b>NOME DO SÓCIO ${socioNumber} : </b> ${Nome} <br />
      <b>EMAIL DO SÓCIO ${socioNumber} : </b> ${Email} <br />
      <b>ESTADO CIVIL ${socioNumber} : </b> ${estadoCivilStr} <br />
      <b>TELEFONE DO SÓCIO ${socioNumber} : </b> ${Telefone} <br />
      <b>DATA DE NASCIMENTO DO SÓCIO ${socioNumber} : </b> ${DataDeNascimento} <br />
      <b>NOME DA MÃE DO SÓCIO ${socioNumber} : </b> ${NomeDaMae} <br />
      <b>RG DO SÓCIO ${socioNumber} : </b> ${Rg} <br />
      <b>SEXO DO SÓCIO ${socioNumber} : </b> ${getSexo(Sexo)} <br />
      <b>NATURALIDADE DO SÓCIO ${socioNumber} : </b> ${Naturalidade} <br />
      <b>CEP DO SÓCIO ${socioNumber} : </b> ${Cep} <br />
      <b>ENDEREÇO DO SÓCIO ${socioNumber} : </b> ${Endereco} <br />
      <b>BAIRRO DO SÓCIO ${socioNumber} : </b> ${Bairro} <br />
      <b>ESTADO DO SÓCIO ${socioNumber} : </b> ${Estado} <br />
      <b>CIDADE DO SÓCIO ${socioNumber} : </b> ${Cidade} <br />`;

            if (EstadoCivil === "0" || EstadoCivil === "5") {
                html += `<b>CPF CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CpfConjuge} <br />
        <b>NOME CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NomeConjuge} <br />
        <b>EMAIL CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EmailConjuge} <br />
        <b>TELEFONE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${TelefoneConjuge} <br />
        <b>DATA DE NASCIMENTO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${DataDeNascimentoConjuge} <br />
        <b>NOME DA MÃE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NomeDaMaeConjuge} <br />
        <b>RG CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${RgConjuge} <br />
        <b>SEXO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${getSexo(SexoConjuge)} <br />
        <b>NATURALIDADE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${NaturalidadeConjuge} <br />
        <b>CEP CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CepConjuge} <br />
        <b>ENDEREÇO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EnderecoConjuge} <br />
        <b>BAIRRO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${BairroConjuge} <br />
        <b>ESTADO CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${EstadoConjuge} <br />
        <b>CIDADE CÔNJUGE DO SÓCIO ${socioNumber} : </b> ${CidadeConjuge} <br />`;
            }
        });

        const {
            NumeroAtualFuncionario,
            ProvaveisEmpregos,
            AutorizaCompartilhaDados,
            AutorizaConsultoriaSebrae,
            AutorizaLgpd,
            AutorizaSicredi,
        } = data;

        html += `<b>FINALIDADE DO CRÉDITO : </b> ${descricaoFinalidadeCredito} <br />
            <b>QUANTOS EMPREGADOS A EMPRESA POSSUI : </b> ${NumeroAtualFuncionario} <br />
            <b>SERÃO GERADOS NOVOS EMPREGOS? QUANTOS? : </b> ${ProvaveisEmpregos} <br />`;

        html += `Autorizo que os meus dados pessoais (nome, e-mail, telefone e cidade onde resido) 
            sejam utilizados para finalidade de retorno e envio de comunicações referentes ao contato solicitado. 
            Após término do tratamento dos dados, os mesmos serão descartados, em conformidade com Lei 13.709/18, 
            Lei Geral de Proteção de Dados. Ao informar meus dados, eu concordo com a Política de Privacidade. : 
            <b>${AutorizaCompartilhaDados ? "SIM" : "NÃO"} </b><br />`;

        html += `Autorizo o Sicredi e/ou Cresol a ter acesso às informações sobre débitos e responsabilidades 
            por garantias constantes em meu nome, perante Instituições de crédito e registradas no Sistema 
            de Informações de crédito do Banco Central (SCR), no SISBACEN ou nos sistemas que venham a 
            complementá-lo e/ou a substituí-lo, conforme normas em vigor, bem como registros em órgão de 
            proteção ao de crédito. Autorizado ainda a obtenção de certidão de débito municipais, estaduais 
            e federais, para fins de análise de crédito: <b>${AutorizaSicredi ? "SIM" : "NÃO"
            } </b><br />`;

        html += `LEI GERAL DE PROTEÇÃO DE DADOS: “Pelo presente documento declaro e estou ciente que a 
            Associação de Garantia de Crédito da Serra Gaúcha terá acesso aos meus dados, pois os 
            mesmos são necessários ao contrato e serviço ora celebrados. Assim, declaro que concordo 
            plenamente em fornecer meus dados à Associação ficando ciente que a mesma procederá com 
            a guarda das informações respeitando integralmente a Lei nº 13.709/2018 – Lei geral de 
            Proteção de Dados”. Além disso, e pelo presente instrumento, declaro que estou ciente de 
            que a Associação de Garantia de Crédito da Serra Gaúcha é parceira do Badesul Desenvolvimento - 
            Agência de Fomento S/A, assumindo o papel de OPERADOR e terá acesso aos meus dados que forem 
            necessários para as finalidades descritas na Política de Proteção de Dados Pessoais e Privacidade 
            do Badesul. Também estou ciente de que, a qualquer tempo, para os casos de tratamento dos dados 
            pessoais classificados com a Base Legal CONSENTIMENTO (oferta de produtos e serviços), eu tenho 
            direito de solicitar a revogação do consentimento a qualquer por meio do canal 
            dados.pessoais@badesul.com.br : <b> ${AutorizaLgpd ? "SIM" : "NÃO"
            } </b><br />`;

        html += `Tenho interesse em consultoria do Sebrae. Autorizo que meus dados sejam alcançados ao Sebrae 
            para que me contatem a m a formalizar a consultoria : <b> ${AutorizaConsultoriaSebrae ? "SIM" : "NÃO"
            }</b> <br/><br/>`;
        html += `        
        </p>
        </body>
        </html>`;

        const sendEmailToEmail1 = process.env.REACT_APP_SEND_EMAIL_TO_EMAIL1;
        const sendEmailToEmail2 = process.env.REACT_APP_SEND_EMAIL_TO_EMAIL2;
        const sendNameToName = process.env.REACT_APP_SEND_NAME_TO_NAME;

        const body = {
            sender: {
                name: `${RazaoSocial}`,
                email: `${Email}`,
            },
            to: [
                {
                    email: sendEmailToEmail1,
                    name: sendNameToName,
                },
                {
                    email: sendEmailToEmail2,
                    name: sendNameToName,
                },
            ],
            subject: "CONTATO RS GARANTI",
            htmlContent: `${html}`,
            attachment: attachment,
        };

        axios
            .post('https://api.brevo.com/v3/smtp/email', body, {
                method: 'POST',
                headers: { ...headers },
            })
            .then((retorno) => {
                resolve(retorno);
            })
            .catch((reject) => {
                resolve(reject);
            });
    });
}

export default sendEmail;
