import Button from '@mui/material/Button';
export const Politica = ({ trigger, setTrigger, children }) => {
    return (trigger) ? (
        <div className='popup'>
            <div className="popup-inner">
                <Button onClick={() => setTrigger(false)}
                    variant="contained"
                    color="success" style={{ position: 'absolute', top: '16px', right: '16px' }}>
                    Fechar
                </Button>
                {children}
            </div>
        </div>
    ) : "";
}
