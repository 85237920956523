let agencias = [
    {
        id: 4486,
        name: 'Camaquã',
        cooperativa: 12,
    },
    {
        id: 4488,
        name: 'Camaquã I',
        cooperativa: 5,
    },
    {
        id: 4489,
        name: 'Camaquã II',
        cooperativa: 5,
    }
]

export {agencias}