import './assets/css/App.css';
import Header from './components/Header';
import BcxSectionA from "./components/BcxSectionA";
import BcxSectionB from "./components/BcxSectionB";
import BcxSectionC from "./components/BcxSectionC";
import BcxSectionD from "./components/BcxSectionD";
import BcxSectionE from "./components/BcxSectionE";
import BcxSectionF from './components/BcxSectionF';
import Form from './components/Form';
import BcxSectionG from './components/BcxSectionG';
import Footer from './components/Footer';

const App = () => {
  return (
    <>
      <Header />
      <BcxSectionA />
      <BcxSectionB />
      <BcxSectionC />
      <BcxSectionD />
      <BcxSectionE />
      <BcxSectionF />
      <Form />
      <BcxSectionG />
      <Footer />
    </>
  );
}

export default App;