/* eslint-disable jsx-a11y/alt-text */
import mainCamaqua from '../assets/imgs/transparent-camaqua.png';
import parceirosImagem from '../assets/imgs/parceiros-imagem.jpg';

const BcxSectionA = () => {
    return (
        <section id="sectionA">
            <div className="container">
                <div className='text'>
                    <p>O Desenvolve Camaquã é um programa que visa apoiar microempreendedores individuais, micro e pequenas empresas, empresas de pequeno porte e agricultores, facilitando o acesso a crédito para investir em seus negócios e promover o desenvolvimento econômico do Município de Camaquã.</p>
                    <p>Este programa tem como objetivo fornecer não apenas acesso ao crédito, mas também ferramentas de gestão, vendas e marketing por meio de capacitações. É uma iniciativa conjunta da Prefeitura de Camaquã, Sebrae RS, Cresol, Sicredi com a carta-garantia da RS Garanti.</p>
                    <p>O propósito é auxiliar as empresas a expandirem seus negócios, gerando emprego, renda e contribuindo para o desenvolvimento econômico da cidade. Trabalhando juntos, podemos sustentar o crescimento e o fortalecimento da economia, proporcionando uma melhor qualidade de vida para a população de Camaquã.</p>
                </div>
                <div className="logos">
                    <img
                        src={mainCamaqua}
                        alt="Desenvolve Camaquã"
                    />
                    <img
                        src={parceirosImagem}
                        alt="Desenvolve Camaquã, RS Garanti, Cresol Sebrae, Sicredi"
                    />
                </div>
            </div>
        </section>
    )
}

export default BcxSectionA