import Aviso from '../assets/imgs/ico-8.png'
import microempreendedorIndividual from "../assets/imgs/microempreendedor-individual.jpeg";
import microempresaAgritultores from "../assets/imgs/microempresa-agricultores.jpeg";
import pequenas_empresas from "../assets/imgs/pequenas-empresas.jpeg";

const BcxSectionE = () => {
    return (
        <section id="sectionE">
            <div className="container">
                <div className="micInd rowCard">
                    <div className="imagem">
                        <img src={microempreendedorIndividual} alt="Microempreendedor individual" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>
                                Microempreendedor
                                <br />
                                Individual
                            </strong>
                        </h3>
                        <p>
                            Com receita bruta anual igual <br /> ou inferior a R$ 81 mil.
                        </p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            R$ 10.000,00 em 36 parcelas (sem carência) de até R$ 370,00.
                            <br />
                            R$ 10.000,00 em 33 parcelas (03 meses de carência) de até <br />R$ 405,00.
                            <br />
                            * A taxa da garantia RS Garanti é de até R$ 662,40.
                        </p>

                        <p></p>
                    </div>
                </div>

                <div className="micEmp rowCard">
                    <div className="imagem">
                        <img src={microempresaAgritultores} alt="Micro empresa e agricultores" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>Microempresa
                                e <br />
                                Produtor Rural
                            </strong>
                        </h3>
                        <p>
                            Com receita bruta anual igual <br /> ou inferior a R$ 360 mil.
                        </p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            Limite de crédito: R$ 50.000,00 em 36 parcelas (sem carência) de até R$ 1.850,00.
                            <br />
                            Limite de crédito: R$ 50.000,00 em 33 parcelas (03 meses de carência) de até R$ 2.050,00.
                            <br />
                            * A taxa da garantia RS Garanti é de até R$ 3.312,00.
                        </p>
                    </div>
                </div>

                <div className="peqEmp rowCard">
                    <div className="imagem">
                        <img src={pequenas_empresas} alt="Pequenas empresas" />
                    </div>

                    <div className="texto">
                        <h3>
                            <strong>
                                Empresa de 
                                <br />
                                Pequeno Porte
                            </strong>
                        </h3>
                        <p>Faturamento anual igual <br /> ou inferior a R$ 4,8 milhões.</p>
                        <p>
                            <strong>Limite de crédito:</strong>
                            <br />
                            R$ 100.000,00 em 36 parcelas (sem carência) de até R$ 3.680,00.
                            <br />
                            R$ 100.000,00 em 33 parcelas (03 meses de carência) de até <br />R$ 4.050,00.
                            <br />
                            * A taxa da garantia RS Garanti é de até R$ 6.624,00.
                        </p>
                    </div>
                </div>

                <div className="aviso">
                    <div className="imgAviso">
                        <img
                            src={Aviso}
                            alt="Icone, formato Balão, com Exclamação dentro"
                        />
                    </div>

                    <div className="textoAviso">
                        <p>
                            • Sujeito a análise de crédito. Parcelas simuladas sem IOF.
                            <br />• Terão acesso a linha associados que apresentarem no mínimo
                            de <strong>01 ano de faturamento completo,</strong> declarados
                            nos documentos indicados e necessários abaixo.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BcxSectionE