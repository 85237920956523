/* eslint-disable jsx-a11y/anchor-is-valid */
import prefeituraCamaqua from '../assets/imgs/prefeitura-camaqua.png'
import salaDoEmpreendedor from '../assets/imgs/sala-do-empreendedor.png'
import rsgaranti from '../assets/imgs/rsgaranti.png'
import cresol from '../assets/imgs/cresol.jpg'
import sebrae from '../assets/imgs/sebrae.jpg'
import sicredi from '../assets/imgs/sicredi.png'

const Footer = () => {
    return (
        <footer>
            <a href='https://camaqua.atende.net/cidadao' target='_blank' rel='noopener noreferrer'>
                <img src={prefeituraCamaqua} alt="Prefeitura de Camaquã" />
            </a>
            <img src={salaDoEmpreendedor} alt="Sala do Empreendedor" />
            <a href='https://www.rsgaranti.org.br/programas_credito.php' target='_blank' rel='noopener noreferrer'>
                <img src={rsgaranti} alt="RS Garanti" style={{ maxWidth: '115px', height: 'auto' }} />
            </a>
            <a href='https://cresol.com.br/' target='_blank' rel='noopener noreferrer'>
                <img src={cresol} alt="Cresol" style={{ maxWidth: '115px', height: 'auto' }} />
            </a>
            <a href='https://sebraers.com.br/' target='_blank' rel='noopener noreferrer'>
                <img src={sebrae} alt="Sebrae" />
            </a>
            <a href='https://www.sicredi.com.br/coop/caminhodasaguasrs/' target='_blank' rel='noopener noreferrer'>
                <img src={sicredi} alt="Sicredi" />
            </a>
        </footer>
    )
}

export default Footer